import { initializeApp, getApps } from "firebase/app"
import { getFirestore } from 'firebase/firestore';

const env = process.env.NODE_ENV

const appConfig = {
    apiKey: "AIzaSyD5A-A7CSZD7hjoLVpkvgJmegN9JMQx-HQ",
    authDomain: "protoworld-app.firebaseapp.com",
    projectId: "protoworld-app",
    storageBucket: "protoworld-app.appspot.com",
    messagingSenderId: "1096045482481",
    appId: "1:1096045482481:web:4c8e74d2edabf3730bb450",
    measurementId: "G-RK1GHLVDDE"
};

if (!getApps().length) {
    const app = initializeApp(appConfig);
    console.log('🔥', env, appConfig.projectId, process.env.NEXT_PUBLIC_DEV_BACKEND)
}

const _firestore = getFirestore();
export const firestore = _firestore