
import * as amplitude from "@amplitude/analytics-browser"; import ReactGA from "react-ga4";

let inited = false

export const track = (eventGroup: string, eventName: string) => {
    if (process.env.REACT_APP_LOCAL) return;
    if (!inited) {
        ReactGA.initialize("G-44E8YGBVFE");
        amplitude.init("6b73ee2eaaf7b080852f742730c96b50"); inited = true;
    }
    amplitude.track(eventName);
    // Send a custom event
    ReactGA.event({
        category: eventGroup,
        action: eventName,
        //   label: "your label", // optional
        //   value: 99, // optional, must be a number
        //   nonInteraction: true, // optional, true/false
        //   transport: "xhr", // optional, beacon/xhr/image
    });
}