import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

const Video = forwardRef(
  (
    props: {
      src: string;
      loop?: boolean;
      controls?: boolean;
      muted?: boolean;
      aspect?: number;
    },
    ref: Ref<IVideoPlayer>
  ) => {
    const vidRef = useRef<HTMLVideoElement>(null);
    const [isWaiting, setIsWaiting] = useState(true);

    useEffect(() => {
      if (!vidRef.current) return;
      const elem = vidRef.current;
      const waitingHandler = () => {
        setIsWaiting(true);
      };
      const playingHandler = () => {
        setIsWaiting(false);
        elem.play();
      };
      elem.addEventListener("waiting", waitingHandler);
      elem.addEventListener("playing", playingHandler);

      return () => {
        elem.removeEventListener("waiting", waitingHandler);
        elem.removeEventListener("playing", playingHandler);
      };
    }, [vidRef]);

    useImperativeHandle(ref, () => ({ play, pause }));
    const play = () => {
      if (!vidRef.current) return;
      vidRef.current.currentTime = 0;
      vidRef.current.play();
    };
    const pause = () => {
      if (!vidRef.current) return;
      vidRef.current.pause();
    };
    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          aspectRatio: props.aspect,
        }}
      >
        <video
          ref={vidRef}
          src={props.src}
          muted
          loop={props.loop}
          controls={false}
          playsInline
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: isWaiting ? 0.1 : 0,
            background: "#000000",
            transition: "opacity 0.2s",
          }}
        >
          <div
            style={{ position: "absolute", right: "5%", bottom: "5%" }}
            className="lds-ring"
          >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }
);

export default Video;
