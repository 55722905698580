import { useEffect, useRef } from "react";

{
  /* <div data-paperform-id="vqp8s8wf"></div><script>(function() {var script = document.createElement('script'); script.src = "https://paperform.co/__embed.min.js"; document.body.appendChild(script); })()</script> */
}
const Survey = (props: { email: string; refSource: string }) => {
  const formRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!formRef.current) return;

    // prefill keys
    formRef.current.setAttribute(
      "prefill",
      `email=${props.email}&ref=${props.refSource}`
    );

    // initialize paperform
    const script = document.createElement("script");
    script.src = "https://paperform.co/__embed.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, [props, formRef]);

  return (
    <div
      ref={formRef}
      className="survey-inner"
      prefill-inherit=""
      data-paperform-id="3d8pcmwu"
      data-spinner={true}
      data-onpagechange="onPageChange"
      style={{ minHeight: "fit-content" }}
    ></div>
  );
};

export default Survey;
