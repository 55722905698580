const Credits = () => {
  return (
    <div>
      <h2>Credits</h2>
      <h3>Showned NFT in the demo</h3>
      <p>
        FORADECOMPASSO, KazuhiroAihara, TAN-TAN, MartinjoSs, Software2050,
        cramped, skunkman, MxVoid, Alonerone, chippy, Chris, Coleman,
        christopher, rutledge, DERUEI eddietree, Ganbrood, Gim, haydiroket, I,
        M, N, O, T, Jie, Liou, John, Karel, kosha, Leon Vilhena, lxtxcx,
        Masbaco, Max, Capacity, maxwell(crumb.tez), merchant, coppola mfers.tez,
        Micah, Alhadeff, mlibty, mrgnhgbyflwrs, Rapp, Scallion, RubenFro Sabato,
        Sky, Goodman, stdystudio.tez, StevenTung, Sutu, ThisisaRobot, TWISTERS
        (project, by, Olga, Feldman), uglykiki, WEIHUANG, Yi-Wen, Lin, ZALA,
        zancan
      </p>
    </div>
  );
};

export default Credits;
