import { useEffect, useState } from "react";
import "./App.css";
import Section from "./Section";
import Credits from "./Credits";
import { useSearchParams } from "react-router-dom";
import Survey from "./Survey";
import Intro from "./Intro";
import { track } from "./utils/tracking";
import EmailForm from "./EmailForm";
const trackedSec: number[] = [];
function App() {
  const [submittedMail, setSubmittedMail] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentSec, setCurrentSec] = useState(0);
  const [showCredits, setShowCredits] = useState(false);

  useEffect(() => {
    const ref = searchParams.get("ref");
    track("base", `ref: ${ref}`);
  }, []);

  useEffect(() => {
    if (trackedSec.includes(currentSec)) return;
    trackedSec.push(currentSec);
    track("view", `Load Section ${currentSec}`);
  }, [currentSec]);

  const demoData_creator: ISection[] = [
    // 0
    {
      id: "intro",
      className: "intro",
      hideNav: true,
      A: {
        video: { src: "./video/low/All_DemoIntro_1.mp4" },
      },
      B: {
        jsx: (
          <>
            <Intro
              onMailSubmitted={(email) => {
                setSubmittedMail(email);
              }}
              onClickMore={() => {
                setCurrentSec(1);
              }}
            />
            {/* <button
              className="btn btn-credit"
              onClick={() => {
                setShowCredits(true);
              }}
            >
              Credits
            </button> */}
          </>
        ),
      },
    },
    // 1
    {
      id: "general",
      A: { video: { src: "./video/low/01_lobbyS_1.mp4" } },
      B: {
        title: "A Next-gen Social Metaverse",
        text: "Free metaverse. Homebase for creators and their fans. ",
      },
    },
    // 2
    {
      id: "social",
      A: { video: { src: "./video/low/02.5_lobby_social.mp4" } },
      B: {
        title: "A Place <br>for Gathering",
        text: "Hangout and share news feed with fans, in an RPG style",
      },
    },
    // 3
    {
      id: "build-together",
      A: {
        video: { src: "./video/low/06_buildTogether_1.mp4" },
      },
      B: {
        title: "Build Together",
        text: "Collaborate with fans, build gallery, social space and live stage together.",
      },
    },
    // 4
    {
      id: "style",
      A: {
        video: { src: "./video/low/09_styles_1.mp4" },
      },
      B: {
        title: "Stylize, <br>with Your Vibe",
        text: "Customizable environment, visual style, avatar to match your community. ",
      },
    },
    // 5
    {
      id: "vip",
      A: {
        video: { src: "./video/low/05_VIP_1.mp4" },
      },
      B: {
        title: "Get Supported",
        text: "Make VIP spaces, costumes, and avatars for your loyal fans. Get support with donations & subscriptions",
      },
    },
    // 6
    {
      id: "live",
      A: {
        video: { src: "./video/low/08_live_1.mp4" },
      },
      B: {
        title: "Virtual Live Shows, Reimagined",
        text: "Make the audience represented as avatars, interact with you and each other during the live event/stream.",
      },
    },
    // 7
    {
      id: "community-city",
      A: {
        video: { src: "./video/low/07_lobbyLarge_1.mp4" },
      },
      B: {
        title: "Your Community City",
        text: "Grow with your community and expand your <b>TRIBE</b> into a city with massive and multiple spaces.",
      },
    },
    // 8
    {
      skip: () => {
        return submittedMail ? true : false;
      },
      id: "if-continue-survey",
      hideNav: true,
      A: {
        video: { src: "./video/low/07_lobbyLarge_1.mp4" },
      },
      B: {
        jsx: (
          <div>
            <h2>Early Access</h2>
            <p>
              Protoworld TRIBE will be available to invited creators and their
              communities in the beta stage. <br /> Do you want to continue
              applying for early access by completing a survey?
              <br /> <br /> The service is free for creators, and no credit card
              is required.
            </p>
            <EmailForm
              onMailSubmitted={(mail) => {
                setSubmittedMail(mail);
                nextSec();
              }}
            />
          </div>
        ),
      },
    },
    // 9
    {
      id: "survey",
      preload: false,
      hideNav: true,
      className: "sec-survey",
      onActive: () => {
        track("view", `Survey loaded`);
      },
      A: {
        desktopOnly: true,
        video: { src: "./video/low/09_styles_1.mp4" },
      },
      B: {
        className: "survey-container",
        jsx: (
          <Survey
            email={submittedMail}
            refSource={searchParams.get("ref") || ""}
          />
        ),
      },
    },
  ];

  const prevSec = () => {
    if (currentSec > 0) setCurrentSec(currentSec - 1);
  };
  const nextSec = () => {
    if (currentSec < demoData_creator.length - 1) setCurrentSec(currentSec + 1);
  };
  return (
    <div className="App">
      {demoData_creator.map((secData, idx) => (
        <Section
          {...secData}
          idx={idx}
          key={idx}
          isActive={currentSec === idx}
          isNext={idx === currentSec + 1}
          showNext={currentSec < demoData_creator.length - 1}
          showPrev={true}
          onClickNext={nextSec}
          onClickPrev={prevSec}
        />
      ))}

      {showCredits && (
        <div className="modal-container">
          <div
            className="modal-overlay"
            onClick={() => {
              setShowCredits(false);
            }}
          ></div>
          <div className="modal">
            <Credits />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
