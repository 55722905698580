import { useState } from "react";
import EmailForm from "./EmailForm";
import { track } from "./utils/tracking";

const Intro = (props: {
  onClickMore: () => void;
  onMailSubmitted: (mail: string) => void;
}) => {
  // const [showApply, setShowApply] = useState(false);
  const [state, setState] = useState<"intro" | "apply" | "success">("intro");
  // const [maileSubmitted, setmaileSubmitted] = useState(false);

  return (
    <>
      {state === "intro" && (
        <>
          <div className="logos">
            <img
              src="../img/logotype.svg"
              alt=""
              style={{ width: "50%", margin: "6px 0" }}
            />
            <img src="../img/tribe.svg" alt="" className="" />
          </div>
          <div>
            <h2 className="desktopOnly">
              Metaverse for creator and their community. A 3D Discord & more
            </h2>
            <h3 className="mobileOnly">
              Metaverse for creator and their community. A 3D Discord & more
            </h3>
            <div className="flex-col">
              <button
                className="btn"
                onClick={() => {
                  track("Click", "Click button: Get Early Access");
                  setState("apply");
                }}
              >
                Get Early Access
              </button>
              <button
                className="btn"
                onClick={() => {
                  track("Click", "Click button: See More");
                  props.onClickMore();
                }}
              >
                See More
              </button>
            </div>
          </div>
        </>
      )}
      {state === "apply" && (
        <>
          <div>
            <h2>Early Access</h2>
            <p>
              Protoworld TRIBE will be availabe to invited creators and their
              community in the beta stage. <br /> <br /> The service is totaly
              free for creators and no credit card is required.
            </p>
            <EmailForm
              onMailSubmitted={(mail) => {
                props.onMailSubmitted(mail);
                setState("success");
              }}
            />
          </div>
        </>
      )}
      {state === "success" && (
        <div>
          <p>
            You're on the list 🥳 <br />
            To get access sooner, check our demo and fill out a survey - or
            close this window if you'd rather not 👋 <br />
            We have a limited number of seats in our beta phase, tailoring the
            experience to our first wave of users.
          </p>
          <button className="btn" onClick={props.onClickMore}>
            Continue 🚀
          </button>
        </div>
      )}
      {(state === "apply" || state === "success") && (
        <button
          className="btn btn-back"
          onClick={() => {
            setState("intro");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
        </button>
      )}
    </>
  );
};

export default Intro;

// intro ->Get Early Access=> mailInput => thankyou, continue? => slside => survey
//        ->See More => slside => Do you want to apply early access by filling out an survey? => bye || Email input?
