import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { useState } from "react";
import { firestore } from "./utils/firebase";
import { track } from "./utils/tracking";

const EmailForm = (props: { onMailSubmitted: (mail: string) => void }) => {
  const [mailInput, setMailInput] = useState("");
  const [warning, setWarning] = useState("");
  const [loading, setLoading] = useState(false);
  const submit = async () => {
    try {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!mailInput.match(validRegex)) {
        setWarning("Invalid email address");
        return;
      }
      const targetDoc = doc(
        firestore,
        `/external/others/tribe-early-access-application/${mailInput}`
      );
      setLoading(true);
      const snap = await getDoc(targetDoc);
      if (snap.exists()) {
        props.onMailSubmitted(mailInput);
        return;
      }
      track("Click", "Submit email");
      await setDoc(targetDoc, {
        email: mailInput,
        submittedAt: serverTimestamp(),
      });
      props.onMailSubmitted(mailInput);
      setLoading(false);
    } catch (error) {
      track("Error", "Submit email failed");
      const e = error as Error;
      setWarning(e.message);
      setLoading(false);
    }
  };
  return (
    <>
      <>
        <div className="input-group">
          <input
            className="input-text"
            type="email"
            placeholder="Your mail address"
            value={mailInput}
            onChange={(e) => {
              setWarning("");
              setMailInput(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter") submit();
            }}
          />
          <button className="btn btn-large" onClick={submit}>
            {!loading && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                />
              </svg>
            )}
            {loading && (
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
          </button>
        </div>
        {warning && <span className="warning">{warning}</span>}
      </>
    </>
  );
};
export default EmailForm;
